import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Grid, Row, Col, IconButton } from "rsuite";
import HeaderLarge from "components/section/headerLarge";
import LayoutMain from "components/layout/main";
import MapProtocolPage from "../../components/map/mapProtocolPage";
import SearchBronnen from "components/ui/searchBronnen";
import SectionWrapper from "components/section/sectionWrapper";
import SourcesOverviewRecent from "../../components/section/sourcesOverviewRecent";
import { Icon } from "@rsuite/icons";
import { BiArrowToRight } from "react-icons/bi";

const BronnenPage = () => {
  const dataBronnen = useStaticQuery(graphql`
    query {
      localSearchBronnen {
        index
        store
      }
    }
  `);
  return (
    <LayoutMain>
      <HeaderLarge
        title="Protocollen"
        description="Op deze pagina vind je alle lokale protocollen. Openbare protocollen zijn bereikbaar via de bijbehorende link. De niet openbare protocollen zijn op te vragen bij de ziekenhuizen via de lokale experts."
      />
      <div style={{ backgroundColor: "#F6F4F5" }}>
        <SectionWrapper padded>
          <Grid>
            <Row>
              <SearchBronnen
                index={dataBronnen.localSearchBronnen.index}
                store={dataBronnen.localSearchBronnen.store}
              />
            </Row>
          </Grid>
        </SectionWrapper>
      </div>
      <SectionWrapper background>
        <MapProtocolPage />
      </SectionWrapper>
      <div
        style={{ padding: "60px 20px", backgroundColor: "rgb(252, 234, 233)" }}
      >
        <Grid>
          <Row>
            <Col>
              <h4
                style={{
                  color: "#000",
                  fontStyle: "italic",
                  marginBottom: "10px",
                }}
              >
                Uitgelicht protocol
              </h4>
              <h3 style={{ color: "black" }}>
                Kunst van het Doseren
                <br />
                FNT Protocol
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  color: "black",
                  lineHeight: "1.8",
                  width: "80%",
                  marginTop: "10px",
                }}
              >
                De Kunst van het doseren is vooral bestemd voor trombosediensten
                die de antistollingsbehandeling van ambulante patiënten
                begeleiden. Het geeft een handvat voor het doseren van vitamine
                K-antagonisten (VKA's) en geldt als de state-of-the-art wat
                betreft de antistollingsbehandeling met VKA's in Nederland. In
                het document zijn leidraden en achtergrondinformatie opgenomen
                met betrekking tot het doseren van VKA's.
              </p>
              <a
                href="https://www.fnt.nl/kwaliteit/de-kunst-van-het-doseren"
                target="_blank"
                rel="noreferrer noopener"
              >
                <IconButton
                  icon={<Icon as={BiArrowToRight} size="1.5em" />}
                  size="md"
                  appearance="primary"
                  style={{ marginTop: "20px" }}
                >
                  Bekijken
                </IconButton>
              </a>
            </Col>
          </Row>
        </Grid>
      </div>
      <SourcesOverviewRecent />
    </LayoutMain>
  );
};

export default BronnenPage;
