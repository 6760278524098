import React from "react";
import { Grid, Row, Col } from "rsuite";
import { useStaticQuery, graphql } from "gatsby";
import SectionWrapper from "./sectionWrapper";
import ExternalSourcePanel from "../ui/externalSourcePanel";

export default function SourcesOverviewRecent() {
  const data = useStaticQuery(graphql`
    query {
      allBronnenCsv(limit: 3, sort: { order: ASC, fields: id }) {
        nodes {
          title
          source
          owner
          type
          description
        }
      }
    }
  `);
  return (
    <>
      <SectionWrapper padded>
        <Grid>
          <Row>
            <Col xs={24}>
              <h4 style={{ margin: "0 0 20px 0", color: "#000" }}>
                Recent toegevoegde lokale protocollen
              </h4>
            </Col>
            {data.allBronnenCsv.nodes.map((node) => (
              <ExternalSourcePanel
                title={node.title}
                description={node.description}
                source={node.source}
                type={node.type}
                category={node.category}
                owner={node.owner}
              />
            ))}
          </Row>
        </Grid>
      </SectionWrapper>
    </>
  );
}
