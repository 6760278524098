import React, { useState } from "react";
import { Tag, Whisper, Popover, Row, Col } from "rsuite";
import { Link, useStaticQuery, graphql } from "gatsby";
import SectionWrapper from "../section/sectionWrapper";
import GoogleMapReact from "google-map-react";
import { BrowserView, isMobile } from "react-device-detect";
import { Icon } from "@rsuite/icons";
import {
  BiTestTube,
  BiPlusMedical,
  BiMapPin,
  BiCheckDouble,
} from "react-icons/bi";
import SearchInput from "./ui/searchInput";
import RegioMapList from "./ui/regioMapList";

export default function MapProtocolPage() {
  const data = useStaticQuery(graphql`
    query {
      allOrganisatiesCsv(filter: { sources: { eq: "yes" } }) {
        nodes {
          id
          lat
          lng
          slug
          title
          type
          subtype
          city
          province
          logo
          sources
        }
      }
      siteSearchIndex {
        index
      }
    }
  `);

  const [items, setItems] = useState(data.allOrganisatiesCsv.nodes);
  const [zoomLevel, setZoomLevel] = useState("8");

  const sortedItems = items.sort(function (a, b) {
    if (a.province.toLowerCase() < b.province.toLowerCase()) return -1;
    if (a.province.toLowerCase() > b.province.toLowerCase()) return 1;
    return 0;
  });

  const groupedItems = sortedItems.reduce((items, item) => {
    if (items[item.province]) {
      items[item.province].push(item);
    } else {
      items[item.province] = [item];
    }
    return items;
  }, {});

  const getMapBounds = (map, maps, items) => {
    const bounds = new maps.LatLngBounds();

    items.forEach((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng));
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
    maps.event.addDomListener(map, "zoom_changed", () => {
      setZoomLevel(map.getZoom());
    });
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, items) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, items);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  return (
    <>
      <SectionWrapper>
        <Row
          style={{
            backgroundColor: "#fff",
            padding: isMobile ? "0 0 20px 0" : "0",
            margin: "0",
          }}
        >
          <Col
            xs={24}
            sm={6}
            md={6}
            style={{
              maxWidth: "100vw",
              backgroundColor: "#fff",
              padding: "0 10px",
            }}
          >
            <h3 style={{ color: "#000", margin: "20px 0 10px 0" }}>
              Organisaties met openbare protocollen
            </h3>
            <Row>
              <Col sm={24}>
                <SearchInput
                  searchIndex={data.siteSearchIndex.index}
                  getResults={setItems}
                  resetData={data.allOrganisatiesCsv.nodes}
                />
              </Col>
            </Row>
            <RegioMapList groupedItems={groupedItems} />
          </Col>
          <BrowserView>
            <Col
              xs={24}
              sm={18}
              md={18}
              style={{
                padding: "0",
                height: "95vh",
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBUxhEM5nehVOVEUAg11-fQrDLmPc8gASg",
                }}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
                options={defaultProps}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  apiIsLoaded(map, maps, items)
                }
              >
                {items.map((node) => {
                  if (node.type === "Trombosedienst") {
                    return (
                      <Marker
                        key={node.id}
                        lat={node.lat}
                        lng={node.lng}
                        title={node.title}
                        slug={node.slug}
                        type={node.type}
                        city={node.city}
                        color="#d78822"
                        icon={BiTestTube}
                        logo={node.logo}
                        small
                      />
                    );
                  } else if (node.type === "Ziekenhuis") {
                    return (
                      <Marker
                        key={node.id}
                        lat={node.lat}
                        lng={node.lng}
                        title={node.title}
                        slug={node.slug}
                        type={node.type}
                        city={node.city}
                        color="#22d788"
                        icon={BiPlusMedical}
                        sources={node.sources}
                        logo={node.logo}
                      />
                    );
                  } else {
                    return (
                      <MarkerRegio
                        key={node.id}
                        lat={node.lat}
                        lng={node.lng}
                        title={node.title}
                        slug={node.slug}
                        type={node.type}
                        city={node.city}
                        color="#2272d7"
                        icon={BiMapPin}
                        logo={node.logo}
                        small
                        zoomLevel={zoomLevel}
                      />
                    );
                  }
                })}
              </GoogleMapReact>
            </Col>
          </BrowserView>
        </Row>
      </SectionWrapper>
    </>
  );
}

const Marker = ({
  title,
  key,
  slug,
  color,
  icon,
  small,
  sources,
  type,
  city,
  logo,
}) => (
  <Whisper
    placement="top"
    trigger="hover"
    controlId="control-id-hover"
    key={key}
    followCursor={true}
    speaker={
      <Popover>
        {logo && (
          <img
            src={`/logo/organisaties/${logo}`}
            alt={title}
            style={{ width: "60px" }}
          />
        )}
        <h6 style={{ color: "#000" }}>{title}</h6>
        {city}
        <br />
        <Tag size="sm" style={{ backgroundColor: color, color: "white" }}>
          {type}
        </Tag>
        {sources === "yes" && (
          <Tag>
            <BiCheckDouble
              style={{ position: "relative", top: "2px", marginRight: "5px" }}
            />
            Protocollen openbaar
          </Tag>
        )}
      </Popover>
    }
  >
    <div
      style={{
        backgroundColor: "#fff",
        borderColor: color,
        color: color,
        borderRadius: "1000px",
        border: "solid 1px",
        position: "relative",
        boxShadow: "0 -3px 1px rgba(0,0,0,0.2)",
        width: small ? "28px" : "32px",
        height: small ? "28px" : "32px",
        top: small ? "-14px" : "-16px",
        left: small ? "-14px" : "-16px",
        zIndex: small ? "1" : "2",
      }}
    >
      <Link to={`/professional/organisatie/profiel/${slug}`}>
        <Icon
          as={icon}
          size={small ? "1.8em" : "2.1em"}
          color={color}
          style={{ position: "absolute", top: "3px", left: "4px" }}
        />
      </Link>
    </div>
  </Whisper>
);

const MarkerRegio = ({
  title,
  key,
  slug,
  color,
  type,
  city,
  logo,
  zoomLevel,
}) => {
  if (zoomLevel > 10) {
    return (
      <Whisper
        placement="top"
        trigger="hover"
        controlId="control-id-hover"
        key={key}
        followCursor={true}
        speaker={
          <Popover>
            {logo && <img src={logo} alt={title} style={{ width: "80px" }} />}
            <h6>{title}</h6>
            {city}
            <br />
            <Tag size="sm" style={{ backgroundColor: color, color: "white" }}>
              {type}
            </Tag>
          </Popover>
        }
      >
        <Link to={`/professional/organisatie/profiel/${slug}`}>
          <div
            style={{
              background:
                "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
              color: color,
              position: "relative",
              left: "-110px",
              top: "-110px",
              width: "220px",
              height: "220px",
              borderRadius: "1000px",
              zIndex: "0",
            }}
          />
        </Link>
      </Whisper>
    );
  } else if (zoomLevel > 9) {
    return (
      <Whisper
        placement="top"
        trigger="hover"
        controlId="control-id-hover"
        key={key}
        followCursor={true}
        speaker={
          <Popover>
            {logo && <img src={logo} alt={title} style={{ width: "80px" }} />}
            <h6>{title}</h6>
            {city}
            <br />
            <Tag size="sm" style={{ backgroundColor: color, color: "white" }}>
              {type}
            </Tag>
          </Popover>
        }
      >
        <Link to={`/professional/organisatie/profiel/${slug}`}>
          <div
            style={{
              background:
                "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
              color: color,
              position: "relative",
              left: "-85px",
              top: "-85px",
              width: "170px",
              height: "170px",
              borderRadius: "1000px",
              zIndex: "0",
            }}
          />
        </Link>
      </Whisper>
    );
  } else if (zoomLevel > 8) {
    return (
      <Whisper
        placement="top"
        trigger="hover"
        controlId="control-id-hover"
        key={key}
        followCursor={true}
        speaker={
          <Popover>
            {logo && <img src={logo} alt={title} style={{ width: "80px" }} />}
            <h6>{title}</h6>
            {city}
            <br />
            <Tag size="sm" style={{ backgroundColor: color, color: "white" }}>
              {type}
            </Tag>
          </Popover>
        }
      >
        <Link to={`/professional/organisatie/profiel/${slug}`}>
          <div
            style={{
              background:
                "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
              color: color,
              position: "relative",
              left: "-65px",
              top: "-65px",
              width: "130px",
              height: "130px",
              borderRadius: "1000px",
              zIndex: "0",
            }}
          />
        </Link>
      </Whisper>
    );
  } else {
    return (
      <Whisper
        placement="top"
        trigger="hover"
        controlId="control-id-hover"
        key={key}
        followCursor={true}
        speaker={
          <Popover>
            {logo && <img src={logo} alt={title} style={{ width: "80px" }} />}
            <h6>{title}</h6>
            {city}
            <br />
            <Tag size="sm" style={{ backgroundColor: color, color: "white" }}>
              {type}
            </Tag>
          </Popover>
        }
      >
        <Link to={`/professional/organisatie/profiel/${slug}`}>
          <div
            style={{
              background:
                "radial-gradient(circle, rgba(34, 114, 215,0.75) 0%, rgba(0,0,0,0) 90%)",
              color: color,
              position: "relative",
              left: "-50px",
              top: "-50px",
              width: "100px",
              height: "100px",
              borderRadius: "1000px",
              zIndex: "0",
            }}
          />
        </Link>
      </Whisper>
    );
  }
};

const defaultProps = {
  center: { lat: 52.1009166, lng: 5.1362914 },
  zoom: 10,
  maxZoom: 11,
  minZoom: 8,
  tilt: 45,
  styles: [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#000",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#acaaab",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          hue: "#c4c3c4",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#dddbdc",
        },
        {
          visibility: "on",
        },
      ],
    },
  ],
};
