import React, { useState } from "react";
import { useFlexSearch } from "react-use-flexsearch";
import { Panel, Col, Row, Button } from "rsuite";
import ExternalSourcePanel from "./externalSourcePanel";
import { Formik, Form, Field } from "formik";

const SearchBronnen = ({ index, store }) => {
  const [query, setQuery] = useState(null);
  const results = useFlexSearch(query, index, store);

  return (
    <div>
      <h3 style={{ color: "#000", marginBottom: "20px" }}>
        Zoek in de protocollen van lokale organisaties
      </h3>
      <Panel
        bordered
        style={{ backgroundColor: "#fff", padding: "10px 10px 20px 10px" }}
      >
        <Formik
          initialValues={{ query: "" }}
          onSubmit={(values, { setSubmitting }) => {
            setQuery(values.query);
            setSubmitting(false);
          }}
        >
          <Form>
            <>
              <Col sm={20} style={{ marginBottom: "10px" }}>
                <Field
                  name="query"
                  className="rs-input"
                  placeholder="Type uw zoekterm.."
                />
              </Col>
              <Col sm={4} style={{ marginBottom: "10px" }}>
                <Button
                  type="submit"
                  appearance="primary"
                  block
                >
                  Zoeken
                </Button>
              </Col>
            </>
          </Form>
        </Formik>
      </Panel>
      <Row>
        {results.length > 0 && (
          <>
            <Col>
              <h4 style={{ color: "#000", margin: "20px 0" }}>
                De resultaten voor zoekterm '{query}':
              </h4>
            </Col>
            {results.map((node) => (
              <ExternalSourcePanel
                title={node.title}
                description={node.description}
                source={node.source}
                type={node.type}
                category={node.category}
                owner={node.owner}
              />
            ))}
          </>
        )}
      </Row>
      {results.length === 0 && query != null && (
        <div style={{ marginTop: "10px" }}>
          <p style={{ color: "#000" }}>Geen resultaten gevonden!</p>
        </div>
      )}
    </div>
  );
};

export default SearchBronnen;
